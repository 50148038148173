exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-all-coaches-page-tsx": () => import("./../../../src/templates/AllCoachesPage.tsx" /* webpackChunkName: "component---src-templates-all-coaches-page-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-coach-category-page-tsx": () => import("./../../../src/templates/CoachCategoryPage.tsx" /* webpackChunkName: "component---src-templates-coach-category-page-tsx" */),
  "component---src-templates-coach-profile-page-tsx": () => import("./../../../src/templates/CoachProfilePage.tsx" /* webpackChunkName: "component---src-templates-coach-profile-page-tsx" */),
  "component---src-templates-contact-form-page-tsx": () => import("./../../../src/templates/ContactFormPage.tsx" /* webpackChunkName: "component---src-templates-contact-form-page-tsx" */),
  "component---src-templates-speakers-workshops-page-tsx": () => import("./../../../src/templates/SpeakersWorkshopsPage.tsx" /* webpackChunkName: "component---src-templates-speakers-workshops-page-tsx" */)
}

